

















import { Component, Vue } from 'vue-property-decorator'
import NavigationBar from '@/components/NavigationBar.vue'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: { NavigationBar },
})
export default class loggedIn extends Vue {
  fab = false

  get sideBar() {
    return this.$route.meta.sidebar
  }

  get hasSideBar() {
    return !!this.$route.meta.sidebar
  }

  appendSitePrefix(url: string) {
    return appendSitePrefix(url)
  }
}
